<template>
  <div class="checkout section-content">
    <div v-if="!isLoaded" class="visually-hidden" aria-live="polite">
      <span> {{ locale['pp.message.loaded'] }} </span>
    </div>
    <div class="row checkout__header">
      <div class="column large-6 small-4 checkout__header-label">
        <span>{{ locale['pp.payment.checkout'] }}</span>
      </div>
      <div class="column large-6 small-8 checkout__header-price">
        <span class="checkout__header-text">
          <span v-if="isLoanAccount">
            {{ locale['pp.amount.summary'] }} {{ amount }}
          </span>
          <span v-if="!isLoanAccount && !isRefundType">
            {{
              isPOFlow
                ? locale['pp.payment.ordersummary']
                : locale['pp.summary.shipping.estimated.total']
            }}
            {{
              productInfo.parts.length > 0
                ? productInfo.price
                : productInfo.additionalAuth.non_return_amount
            }}</span
          >
        </span>
      </div>
    </div>
    <div class="checkout__header">
      <h1>{{ locale['pp.summary.shipping.info'] }}</h1>
      <p class="body__text">{{ locale['pp.summary.shipping.product'] }}</p>
    </div>
    <!-- Error Message-->
    <b-col
      v-if="errorMessageKey"
      ref="errorMessageText"
      role="region"
      aria-label="Payment error"
      aria-live="polite"
      tabindex="-1"
      cols="12"
      class="error-message"
    >
      <div class="error-message__text">
        <img
          alt="Error"
          class="input-field__icons"
          src="../../assets/warning.svg"
        />
        <span
          aria-hidden="true"
          class="error-message-form"
          v-html="locale[errorMessageKey]"
        ></span>
      </div>
    </b-col>

    <!-- Payment Selection  -->
    <div class="row payment__selection">
      <div class="column large-6 medium-6 small-12 form-selector-group">
        <!-- Billing Summary -->
        <!-- Credit Card Section - Changed CVV type to password-->
        <div id="creditcard__section">
          <b-card>
            <div
              id="creditcard__section-details"
              class="creditcard__section-details"
            >
              <template>
                <div class="row">
                  <fieldset class="column large-12 shipping_address__container">
                    <div
                      v-if="
                        isCountrySettings === false || isStateFetched === false
                      "
                      class="button-spinner"
                    >
                      <app-moon-loader color="black" size="25px" />
                    </div>
                    <template
                      v-if="
                        isCountrySettings === true &&
                        isStateFetched === true &&
                        selectedCountry !== 'country'
                      "
                    >
                      <div
                        v-for="{
                          component,
                          eventHandlers,
                          key,
                          inputProps
                        } in getShippingInputs('shipping')"
                        :key="key"
                        :class="{
                          [`creditcard__section--field`]: true
                          //   'large-6 inline-field':
                          //     inlineFields.includes(inputProps.name) && !isChile
                        }"
                      >
                        <component
                          :is="component"
                          v-bind="inputProps"
                          v-on="eventHandlers"
                        ></component>
                      </div>

                      <app-select
                        :autocomplete="AUTOCOMPLETE_ATTRIBUTES['country']"
                        class="creditcard__section--field"
                        v-if="productInfo.transactionType !== 'AGR'"
                        name="country"
                        :label="locale['pp.address.country']"
                        :options="[{ text: selectedCountryText }]"
                        :selected="selectedCountryText"
                        :error="$v.country ? $v.country.$error : false"
                        @onKeyup="onKeyupfield"
                        @onBlur="validateDetailsField"
                        :disabled="true"
                      />
                      <div v-if="isPOFlow" class="form-alert">
                        <p
                          v-html="
                            this.locale[
                              'pp.summary.shipping.change.business.message'
                            ]
                          "
                        ></p>
                      </div>
                      <div v-else class="form-alert">
                        <p
                          v-html="
                            this.locale['pp.summary.shipping.change.message']
                          "
                        ></p>
                      </div>
                      <!-- email & phone -->
                      <!-- Commented out for now. Will be added back with Griffin integration -->
                      <!-- <app-input-new
                      name="email"
                      :readonly="isLoading"
                      :required="true"
                      :characterCountEnabled="true"
                      :error="
                        $v.shippingInfo['email']
                          ? $v.shippingInfo['email'].$invalid
                          : false
                      "
                      :placeholder="locale['pp.summary.shipping.email']"
                      :fieldValue="shippingInfo['email']"
                      :multiByteMessage="
                        locale[MULTIBYTE_ERROR_MESSAGE['email']]
                      "
                      :multiByteCountries="multiByteCountries"
                      type="text"
                      @onBlur="validateDetailsField"
                      @onChange="fieldChange()($event)"
                      @onInputApply="onKeyupfield"
                    />
                    <p class="font-body-tight">
                      {{ locale['pp.summary.shipping.email.status'] }}
                    </p>
                    <app-input-new
                      name="phoneNumber"
                      :readonly="isLoading"
                      :required="true"
                      :characterCountEnabled="true"
                      :error="
                        $v.shippingInfo['phoneNumber']
                          ? $v.shippingInfo['phoneNumber'].$invalid
                          : false
                      "
                      :placeholder="locale['pp.summary.shipping.phone']"
                      :fieldValue="shippingInfo[`phoneNumber`]"
                      :multiByteMessage="
                        locale[MULTIBYTE_ERROR_MESSAGE['phoneNumber']]
                      "
                      :multiByteCountries="multiByteCountries"
                      type="text"
                      @onBlur="validateDetailsField"
                      @onChange="fieldChange()($event)"
                      @onInputApply="onKeyupfield"
                    />
                    <div class="smsOptIn">
                      <input
                        v-model="smsUpdatesOptIn"
                        type="checkbox"
                        name="smsUpdatesOptIn"
                        id="smsUpdatesOptIn"
                        :required="true"
                        :value="true"
                      />
                      <label
                        class="checkbox__label"
                        id="smsUpdatesOptInLabel"
                        for="smsUpdatesOptIn"
                      >
                        <p>{{ locale['pp.summary.shipping.sms.message'] }}</p>
                      </label>
                    </div>
                    -->
                    </template>
                  </fieldset>
                  <!-- Return Address Section -->
                  <template v-if="collectReturnAddress">
                    <fieldset class="column large-12 return_address__container">
                      <legend class="creditcard__section-addressheading">
                        {{ locale['pp.summary.return.address'] }}
                      </legend>
                      <span v-if="!isPOFlow" class="checkbox__container">
                        <input
                          v-model="returnAddressSameAsShipping"
                          type="checkbox"
                          name="returnAddressSameAsShipping"
                          id="returnAddressSameAsShipping"
                          :required="true"
                          :value="true"
                        />
                        <label
                          class="checkbox__label"
                          id="returnAddressSameAsShippingLabel"
                          for="returnAddressSameAsShipping"
                          >{{ locale['pp.summary.returntoshipping'] }}</label
                        >
                      </span>
                      <template v-if="!returnAddressSameAsShipping">
                        <div
                          v-if="
                            isCountrySettings === false ||
                            isStateFetched === false
                          "
                          class="button-spinner"
                        >
                          <app-moon-loader color="black" size="25px" />
                        </div>
                        <template
                          v-if="
                            isCountrySettings === true &&
                            isStateFetched === true &&
                            selectedCountry !== 'country'
                          "
                        >
                          <div
                            v-for="{
                              component,
                              eventHandlers,
                              key,
                              inputProps
                            } in getShippingInputs('return')"
                            :key="key"
                            :class="{
                              [`creditcard__section--field`]: true
                              //   'large-6 inline-field':
                              //     inlineFields.includes(inputProps.name) &&
                              //     !isChile
                            }"
                          >
                            <component
                              :is="component"
                              v-bind="inputProps"
                              v-on="eventHandlers"
                            ></component>
                          </div>

                          <app-select
                            :autocomplete="AUTOCOMPLETE_ATTRIBUTES['country']"
                            class="creditcard__section--field"
                            v-if="productInfo.transactionType !== 'AGR'"
                            name="country"
                            :label="locale['pp.address.country']"
                            :options="[{ text: selectedCountryText }]"
                            :selected="selectedCountryText"
                            :error="$v.country ? $v.country.$error : false"
                            @onKeyup="onKeyupfield"
                            @onBlur="validateDetailsField"
                            :disabled="true"
                          />
                          <div v-if="isPOFlow" class="form-alert">
                            <p
                              v-html="
                                this.locale[
                                  'pp.summary.shipping.change.business.message'
                                ]
                              "
                            ></p>
                          </div>
                        </template>
                      </template>
                    </fieldset>
                  </template>
                  <!-- Tax Information -->
                  <template v-if="collectTaxDetails">
                    <fieldset class="column large-12 return_address__container">
                      <div class="form-tooltip-wrapper form-icons-focusable">
                        <legend class="tax__section-addressheading form-label">
                          {{ locale['pp.summary.tax.info'] }}
                        </legend>

                        <div
                          class="form-tooltip form-tooltip-after form-tooltip-after-override"
                        >
                          <button
                            class="form-tooltip-button"
                            aria-describedby="taxform-tooltip-01"
                          >
                            <span
                              class="form-icons form-icons-info19"
                              aria-hidden="true"
                            ></span>
                            <span class="visuallyhidden">{{
                              locale['pp.summary.tax.message']
                            }}</span>
                          </button>
                          <div
                            id="taxform-tooltip-01"
                            aria-hidden="true"
                            class="form-tooltip-info form-tooltip-info-override form-tooltip-pointer-middle"
                            role="tooltip"
                          >
                            <p
                              class="form-tooltip-content form-tooltip-content-override"
                            >
                              {{ locale['pp.summary.tax.message'] }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <!-- select tax type -->
                      <template>
                        <div
                          v-for="{ type } in TAX_FIELDS[countrycode]"
                          :key="type"
                        >
                          <template v-if="TAX_FIELDS[countrycode].length !== 1">
                            <div
                              class="form-selector form-selector-twocol-threeline"
                            >
                              <input
                                v-on:click="selectTaxType"
                                :id="`taxtype_${type}`"
                                class="form-selector-input"
                                name="taxType"
                                type="radio"
                                :value="type"
                              />
                              <label
                                :for="`taxtype_${type}`"
                                class="form-selector-label"
                              >
                                <span class="row">
                                  <span
                                    class="form-selector-left-col column large-6"
                                  >
                                    <span class="form-selector-title">{{
                                      type
                                    }}</span>
                                  </span>
                                </span>
                              </label>
                            </div>
                          </template>
                          <!-- Tax Type Selection -->
                          <b-collapse
                            id="creditcard__section"
                            :visible="type === taxDetails.type"
                          >
                            <div
                              v-for="{
                                component,
                                eventHandlers,
                                inputProps
                              } in getTaxInputs(type)"
                              :key="inputProps.name"
                              :class="{
                                [`creditcard__section--field`]: true
                              }"
                            >
                              <!-- Tax Details Form -->
                              <component
                                :is="component"
                                v-bind="inputProps"
                                v-on="eventHandlers"
                              ></component>
                            </div>
                          </b-collapse>
                        </div>
                      </template>
                    </fieldset>
                    <!-- Tax Address -->

                    <template v-if="collectTaxAddress">
                      <fieldset
                        class="column large-12 return_address__container"
                      >
                        <legend class="creditcard__section-addressheading">
                          {{ locale['pp.summary.tax.address'] }}
                        </legend>
                        <input
                          v-model="taxAddressSameAsShipping"
                          type="checkbox"
                          name="taxAddressSameAsShipping"
                          id="taxAddressSameAsShipping"
                          :required="true"
                          :value="true"
                        />
                        <label
                          class="checkbox__label"
                          id="taxAddressSameAsShippingLabel"
                          for="taxAddressSameAsShipping"
                          ><p>
                            {{ locale['pp.payment.address.billingisshipping'] }}
                          </p></label
                        >
                        <template v-if="!taxAddressSameAsShipping">
                          <div
                            v-if="
                              isCountrySettings === false ||
                              isStateFetched === false
                            "
                            class="button-spinner"
                          >
                            <app-moon-loader color="black" size="25px" />
                          </div>
                          <template
                            v-if="
                              isCountrySettings === true &&
                              isStateFetched === true &&
                              selectedCountry !== 'country'
                            "
                          >
                            <div
                              v-for="{
                                component,
                                eventHandlers,
                                key,
                                inputProps
                              } in getShippingInputs('tax')"
                              :key="key"
                              :class="{
                                [`creditcard__section--field`]: true
                                // 'large-6 inline-field':
                                //   inlineFields.includes(inputProps.name) &&
                                //   !isChile
                              }"
                            >
                              <component
                                :is="component"
                                v-bind="inputProps"
                                v-on="eventHandlers"
                              ></component>
                            </div>
                          </template>
                        </template>
                      </fieldset>
                    </template>
                  </template>
                </div>
              </template>
            </div>
          </b-card>
        </div>
      </div>
    </div>
    <!-- Continue Buttton -->
    <div class="row continue__review">
      <div class="column large-6 medium-6 small-12">
        <app-button
          id="continue-payment-form"
          :type="buttonSizeChange"
          :disabled="!isFormValid"
          :isLoading="isLoading"
          @onClick="nextStep"
        >
          {{ locale['pp.payment.continuereview'] }}
        </app-button>
      </div>
    </div>

    <div
      id="loading-text"
      role="alert"
      class="sr-only"
      aria-live="polite"
    ></div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import AppButton from '@/components/app-button'
import AppInputNew from '@/components/app-input-new'
import AppSelect from '@/components/app-select'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import router from '../../router'
import store from '../../store'
import { required } from 'vuelidate/lib/validators'
import validationMixin from '@/mixins/validationMixin'
import Modal from '@marcom/ac-modal'
import {
  focusOnSelector,
  buttonType,
  isPOBoxAddress,
  containsSpecialCharacters,
  byteCount
} from '@/utilities/utils'
import { TAX_FIELDS } from '@/utilities/constants'
import { ARIA_LIVE_TIME_OUT_DELAY } from '@/utilities/timeout'
import { creditCardIds } from './utilities/matchCreditCard'
import ExternalPaymentRadioButton from '@/components/ExternalPaymentRadioButton'
import {
  AUTOCOMPLETE_ATTRIBUTES,
  MULTIBYTE_ERROR_MESSAGE
} from '@/utilities/constants'
import addressStateToPayloadMapping from './utilities/billingAddress'

export default {
  components: {
    AppButton,
    AppInputNew,
    AppSelect,
    appMoonLoader: MoonLoader,
    ExternalPaymentRadioButton,
    AppInputNew
  },

  title() {
    return `${this.locale['pp.payment.checkout']}`
  },

  data() {
    return {
      showed: '',
      isPaymentSelected: true,
      isModalClose: true,
      isLoaded: false,
      visible: false,
      windowWidth: 0,
      shouldZipCodeLookUp: false, // tells us if backend validation is needed for this country
      isZipCodeRequired: false, // tells us if zipcode is required for this country
      isLoanAccount: false,
      usesSelectInput: ['state', 'district', 'city'],
      inlineFields: ['city', 'postalcode'],
      isNextStepProcessing: false,
      ARIA_LIVE_TIME_OUT_DELAY,
      MULTIBYTE_ERROR_MESSAGE,
      AUTOCOMPLETE_ATTRIBUTES,
      smsUpdatesOptIn: false,
      TAX_FIELDS
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.mainStore.localization,
      productInfo: (state) => state.productStore,
      amount: (state) => state.mainStore.amount,
      accountType: (state) => state.mainStore.accountType,
      states: (state) => state.mainStore.states,
      countrySettings: (state) => state.mainStore.countrySettings,
      countryFields: (state) => state.mainStore.countryFields,
      productDetails: (state) => state.productStore,
      shippingInfo: (state) => state.formStore.shippingInfo,
      taxDetails: (state) => state.formStore.taxDetails,
      isLoading: (state) => state.formStore.createQuote.status.isLoading,
      isFetched: (state) => state.formStore.status.isFetched,
      error: (state) => state.formStore.status.error,
      country: (state) => state.mainStore.details.country_3,
      errorMessageKey: (state) => state.formStore.shippingInfo.errorMessageKey,
      errorField: (state) => state.formStore.errorDetails.errorField,
      isRtl: (state) => state.mainStore.isRtl,
      additionalAuth: (state) => state.productStore.additionalAuth,
      region: (state) => state.mainStore.details.region,
      type: (state) => state.mainStore.details.acceptance_types,
      detailsInfo: (state) => state.mainStore.details,
      isCountrySettings: (state) => state.mainStore.isCountrySettings,
      isCountryFields: (state) => state.mainStore.isCountryFields,
      countrycode: (state) => state.mainStore.countrycode,
      selectCountryOptions: (state) => state.mainStore.countryFields,
      termsChecked: (state) => state.termsStore.termStatus,
      selectedCountry: (state) => state.mainStore.selectedCountry,
      selectedCountryText: (state) => state.mainStore.selectedCountryText,
      localeCountry: (state) => state.mainStore.localeCountry,
      tokenValue: (state) => state.mainStore.tokenValue,
      iswebpayEligibleCountry: (state) =>
        state.mainStore.iswebpayEligibleCountry,
      isStateListAvail: (state) => state.mainStore.isStateListAvail,
      isStateFetched: (state) => state.mainStore.isStateFetched,
      isRefundType: (state) => state.mainStore.refundType,
      isRefundVerified: (state) => state.formStore.isRefundVerified,
      text_key: (state) => state.mainStore.details.text_key,
      zipCodeLookup: (state) => state.formStore.zipCodeLookup,
      multiByteCountries: (state) => state.mainStore.details.multiByteCountries,
      returnAddressSameAsShipping: (state) =>
        state.formStore.returnAddressSameAsShipping
    }),
    ...mapGetters([
      'collectReturnAddress',
      'isChile',
      'getDistrictsSelectOptions',
      'getCitiesInDistrict',
      'getCitiesSelectOptions',
      'languageCode',
      'collectTaxDetails',
      'collectTaxAddress',
      'hasMultipleTaxTypes',
      'isPOFlow'
    ]),

    selectOptions() {
      const states = this.states['stateCodes']
      const textArray = this.states['stateDescriptions']
      let formatedOptions = [
        {
          value: null,
          text: this.locale['address.state']
        }
      ]
      states.map((item, index) => {
        formatedOptions.push({
          value: item,
          text: textArray[index]
        })
      })
      return formatedOptions
    },

    buttonSizeChange() {
      return buttonType(this.windowWidth)
    },
    isFormValid() {
      if (!this.isCountrySettings) {
        return false
      }
      const isShippingAddressValid =
        !this.$v.shippingInfo.shippingAddress.$invalid
      /**
       * when return address's is same as shipping or when return address need not be collected, return address validity need not be checked
       */
      const isReturnAddressValid =
        this.returnAddressSameAsShipping || !this.collectReturnAddress
          ? true
          : !this.$v.shippingInfo.returnAddress.$invalid

      const isTaxAddressValid =
        this.taxAddressSameAsShipping || !this.collectTaxAddress
          ? true
          : !this.$v.shippingInfo.taxAddress.$invalid

      //   Commented out for now. Will be added back with Griffin integration
      //   const isEmailValid = !this.$v.shippingInfo['email'].$invalid
      //   const isPhoneValid = !this.$v.shippingInfo['phoneNumber'].$invalid

      const collectTaxDetails = this.collectTaxDetails

      const isTaxInfoValid =
        !collectTaxDetails ||
        (!this.$v.taxDetails.$invalid &&
          (this.taxDetails.type || !this.hasMultipleTaxTypes)) // form is valid and tax type is selected

      if (
        isShippingAddressValid &&
        isReturnAddressValid &&
        isTaxAddressValid &&
        isTaxInfoValid &&
        !this.isNextStepProcessing
      ) {
        return true
      }
    },
    taxAddressSameAsShipping: {
      get() {
        return this.$store.state.formStore.taxAddressSameAsShipping
      },
      set(value) {
        this.$store.commit('TOGGLE_TAX_ADDRESS_SAME_AS_SHIPPING', value)
      }
    },
    returnAddressSameAsShipping: {
      get() {
        return this.$store.state.formStore.returnAddressSameAsShipping
      },
      set(value) {
        this.$store.commit('TOGGLE_RETURN_ADDRESS_SAME_AS_SHIPPING', value)
      }
    }
  },
  mixins: [validationMixin],
  validations() {
    const checkpostalcode = (value) => {
      if (this.selectedCountry === 'JPN') {
        let postCodeVal = ''
        if (value !== null) {
          postCodeVal = value.replace('−', '-').replace('ー', '-')
        }

        if (value !== null) {
          if (postCodeVal.slice(3, 4) !== '-') {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      }
    }
    const checkEmail = (value) => {
      /**
       * invalid validation (same as the one used by Core)
       */
      const regex =
        /^[\w!#$%&‘*+/=?{|}~^-]+(?:\.[\w!#$%&'*+/=?{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/

      // standalone validator ideally should not assume a field is required
      if (value === '') {
        return true
      }
      return regex.test(value)
    }

    const validateStreetAddress = (type, field) => (value) => {
      const isValid = !isPOBoxAddress(value)
      const message = this.locale['pp.payment.error.highlight.noPoBoxAddress']
      if (!isValid) {
        this.$store.commit('SET_ERROR_MESSAGE_FOR_FIELD', {
          type,
          field,
          message
        })
      } else {
        this.$store.commit('UNSET_ERROR_MESSAGE_FOR_FIELD', {
          type,
          field,
          message
        }) // unsets the error message
      }
      return isValid
    }

    const specialCharacterCheck = (type, field) => (value) => {
      const isValid = !containsSpecialCharacters(value)
      const message =
        this.locale['pp.payment.error.highlight.noSpecialCharacters']

      if (!isValid) {
        this.$store.commit('SET_ERROR_MESSAGE_FOR_FIELD', {
          type,
          field,
          message
        })
      } else {
        this.$store.commit('UNSET_ERROR_MESSAGE_FOR_FIELD', {
          type,
          field,
          message
        }) // unsets the error message
      }
      return isValid
    }

    const characterLimitCheck = (type, field) => (value) => {
      const fieldSettings = this.countrySettings.find(item => item.name === field)
      const maxLength = fieldSettings ? fieldSettings.length : 30
      const isValid = byteCount(value) <= maxLength 
      const message = this.locale['pp.payment.error.highlight.characterlimit']
      if (!isValid) {
        this.$store.commit('SET_ERROR_MESSAGE_FOR_FIELD', {
          type,
          field,
          message
        })
      } else {
        this.$store.commit('UNSET_ERROR_MESSAGE_FOR_FIELD', {
          type,
          field,
          message
        })
      }
      return isValid
    }

    let settings = {
      shippingInfo: {
        shippingAddress: {
          fields: {
            street1: {
              validateStreetAddress: validateStreetAddress(
                'shipping',
                'street1'
              )
            },
            street2: {
              validateStreetAddress: validateStreetAddress(
                'shipping',
                'street2'
              )
            },
            street3: {
              validateStreetAddress: validateStreetAddress(
                'shipping',
                'street3'
              )
            },
            street4: {
              validateStreetAddress: validateStreetAddress(
                'shipping',
                'street4'
              )
            },
            orgName: {}
          }
        }
        // Commented out for now. Will be added back with Griffin integration
        // email: {
        //   required,
        //   checkEmail
        // },
        // phoneNumber: {
        //   required
        // }
      },
      country: {},
      taxDetails: {
        fields: {}
      }
    }

    /**
     * validation schema for tax form
     */
    if (this.collectTaxDetails) {
      settings.taxDetails = {
        fields: {}
      }
      const taxType = this.taxDetails.type

      // wait for tax type to be chosen if there are multiple types
      if (taxType || !this.hasMultipleTaxTypes) {
        const fields = this.TAX_FIELDS[this.selectedCountry].find(
          (taxTypes) => taxTypes.type === taxType
        ).fields
        fields.map(({ name, required: isRequired, isValid }) => {
          settings.taxDetails.fields[name] = {}
          if (isRequired) settings.taxDetails.fields[name].required = required
          if (isValid) settings.taxDetails.fields[name].isValid = isValid
        })
      }
    }
    if (this.collectReturnAddress) {
      settings.shippingInfo.returnAddress = {
        fields: {
          street1: {
            validateStreetAddress: validateStreetAddress('return', 'street1')
          },
          street2: {
            validateStreetAddress: validateStreetAddress('return', 'street2')
          },
          street3: {
            validateStreetAddress: validateStreetAddress('return', 'street3')
          },
          street4: {
            validateStreetAddress: validateStreetAddress('return', 'street4')
          }
        }
      }
    }
    settings.shippingInfo.taxAddress = { fields: {} }

    // dynamic validation

    this.countrySettings.forEach((item) => {
      // adds field to settings if field does not exist in settings
      if (
        this.collectReturnAddress &&
        !settings.shippingInfo.returnAddress.fields[item.name]
      ) {
        settings.shippingInfo.returnAddress.fields[item.name] = {}
      }
      if (!settings.shippingInfo.shippingAddress.fields[item.name]) {
        settings.shippingInfo.shippingAddress.fields[item.name] = {}
      }
      if (item.required) {
        if (item.name === 'postalcode' && this.selectedCountry === 'JPN') {
          settings.shippingInfo.shippingAddress.fields[item.name] = {
            required,
            checkpostalcode
          }
          if (this.collectReturnAddress) {
            settings.shippingInfo.returnAddress.fields[item.name] = {
              required,
              checkpostalcode
            }
          }
          settings.shippingInfo.taxAddress.fields[item.name] = {
            required,
            checkpostalcode
          }

          this.shouldZipCodeLookUp = true
        } else {
          settings.shippingInfo.shippingAddress.fields[item.name] = {
            required,
            ...settings.shippingInfo.shippingAddress.fields[item.name]
          }
          if (this.collectReturnAddress) {
            settings.shippingInfo.returnAddress.fields[item.name] = {
              required,
              ...settings.shippingInfo.returnAddress.fields[item.name]
            }
          }
          settings.shippingInfo.taxAddress.fields[item.name] = { required }
        }
      }
      if (item.name === 'postalcode') {
        this.shouldZipCodeLookUp = item.zipLookup
        if (item.required) this.isZipCodeRequired = true
      }
    })
    settings.country = { required }

    const shippingAddressFieldsSettings =
      settings.shippingInfo.shippingAddress.fields
    /**
     * attach special character validation for all address fields
     */
    Object.keys(shippingAddressFieldsSettings).forEach((field) => {
      shippingAddressFieldsSettings[field] = {
        ...shippingAddressFieldsSettings[field],
        specialCharacterCheck: specialCharacterCheck('shipping', field),
        characterLimitCheck: characterLimitCheck('shipping', field)
      }
    })

    if (this.collectReturnAddress) {
      const returnAddressFieldsSettings =
        settings.shippingInfo.returnAddress.fields
      Object.keys(returnAddressFieldsSettings).forEach((field) => {
        returnAddressFieldsSettings[field] = {
          ...returnAddressFieldsSettings[field],
          specialCharacterCheck: specialCharacterCheck('return', field),
          characterLimitCheck: characterLimitCheck('return', field)
        }
      })
    }

    return settings
  },
  created: function () {
    const that = this
    setTimeout(function () {
      that.isLoaded = true
    }, ARIA_LIVE_TIME_OUT_DELAY)
    this.checkAccountType()
  },
  watch: {
    errorMessageKey(val) {
      if (val) {
        this.$nextTick(() => {
          // DOM is now updated
          const errorCol = this.$refs.errorMessageText
          errorCol.focus()
          errorCol.scrollIntoView()
        })
      }
    },
    selectedCountry(oldval, val) {
      if (oldval !== val) {
      }
    },
    isCountrySettings: function (val, oldVal) {
      if (val === false) {
        var node = document.createElement('span')
        var textnode = document.createTextNode('loading address fields')
        node.appendChild(textnode)
        document.getElementById('loading-text').appendChild(node)
      } else {
        var list = document.getElementById('loading-text')
        list.removeChild(list.childNodes[0])
      }
    },
    isStateFetched: function (val, oldVal) {
      if (val === false) {
        var node = document.createElement('span')
        var textnode = document.createTextNode('loading address fields')
        node.appendChild(textnode)
        document.getElementById('loading-text').appendChild(node)
      } else {
        var list = document.getElementById('loading-text')
        list.removeChild(list.childNodes[0])
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)

      //Init
      this.getWindowWidth()
    }),
      focusOnSelector('h1')
    if (this.errorField === '') {
      this.$store.dispatch('defaultFormfield')
    }

    if (this.isPOFlow) {
      this.returnAddressSameAsShipping = false
    }

    if (
      this.$route.name === 'ShippingInfo' &&
      this.type === 'Active' &&
      !this.termsChecked
    ) {
      const { token, locale } = this.$route.params

      this.$router.push(`/${token}/${locale}/terms`)
    } else if (this.isCountrySettings === false) {
      this.$store.dispatch('getCountrySetting', this.countrycode).then(() => {
        if (!this.isCountrySettings) {
          this.$router.push(`/${token}/${locale}/error`)
        }
      })
    }
    this.isModalClose = false
    if (this.productInfo.transactionType !== 'AGR') {
      this.$store.dispatch('setCountrySelected', this.country)
      this.$store.dispatch('getCountrySetting', this.country).then(() => {
        if (!this.isCountrySettings) {
          this.$router.push(`/${token}/${locale}/error`)
        }
      })
    }

    /**
     * when shipping info already has state value, we fetch the corresponding Districts
     */
    if (this.shippingInfo) {
      if (this.isChile) {
        // populate districts based on postalcode
        if (this.shippingInfo.shippingAddress.fields.state) {
          this.$store.dispatch('fetchDistrictsForShippingInfo', {
            country: this.selectedCountry,
            stateCode: this.shippingInfo.shippingAddress.fields.state,
            languageCode: this.languageCode,
            type: 'shipping'
          })
        }
        if (this.shippingInfo.returnAddress.fields.state) {
          this.$store.dispatch('fetchDistrictsForShippingInfo', {
            country: this.selectedCountry,
            stateCode: this.shippingInfo.returnAddress.fields.state,
            languageCode: this.languageCode,
            type: 'return'
          })
        }
      } else {
        // populate cities based on postalcode
        if (this.shippingInfo.shippingAddress.fields.postalcode) {
          this.$store.dispatch('fetchStates', {
            zipCode: this.shippingInfo.shippingAddress.fields.postalcode,
            country: this.selectedCountry,
            field: 'postalcode',
            type: 'shipping'
          })
        }
        if (
          this.shippingInfo.returnAddress.fields.postalcode &&
          this.shouldZipCodeLookUp
        ) {
          this.$store.dispatch('fetchStates', {
            zipCode: this.shippingInfo.returnAddress.fields.postalcode,
            country: this.selectedCountry,
            field: 'postalcode',
            type: 'return'
          })
        }
      }
    }

    /**
     * if there is only 1 tax type - initialize tax fields in state without waiting for user to choose a tax type
     */
    if (
      this.collectTaxDetails &&
      this.TAX_FIELDS[this.countrycode].length === 1
    ) {
      this.$store.dispatch('changeTaxType', null)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  beforeRouteLeave(to, _from, next) {
    if (!this.isFormValid && to.name === 'PaymentForm') {
      this.$v.$touch()
      next(false)
    } else {
      next()
    }
  },
  methods: {
    checkAccountType() {
      if (this.accountType) {
        this.visible = true
        this.isLoanAccount = true
        this.isPaymentSelected = true
        this.paymentMethod = 'cc'
      } else {
        this.visible = false
        this.isLoanAccount = false
      }
    },
    getWindowWidth(event) {
      this.windowWidth = document.documentElement.clientWidth
    },
    showSelectInput(fieldName, type) {
      switch (fieldName) {
        case 'state':
          return (
            (this.isStateListAvail === true &&
              this.productInfo.transactionType !== 'AGR') ||
            this.productInfo.transactionType === 'AGR'
          )
        case 'district':
          return this.getDistrictsSelectOptions(type).length
        case 'city': {
          if (
            this.shippingInfo[`${type}Address`].status.otherOptionChosenForCity
          ) {
            /**
             * user has chosen 'Other' option. Don't show Select input
             */
            return false
          }
          return this.getCitiesSelectOptions(type).length
        }
        default:
          return false
      }
    },
    /**
     * for displaying text input instead of a select when select options are not available
     */
    showAlternativeTextInput(fieldName, type) {
      if (!this.usesSelectInput.includes(fieldName)) return false

      switch (fieldName) {
        case 'state':
          return (
            this.isStateListAvail !== true &&
            this.productInfo.transactionType !== 'AGR'
          )
        case 'district':
          return !this.getDistrictsSelectOptions(type).length
        case 'city': {
          if (
            this.shippingInfo[`${type}Address`].status.otherOptionChosenForCity
          ) {
            /**
             * user has chosen 'Other' option. Show text input
             */
            return true
          }
          return !this.getCitiesSelectOptions(type).length
        }
        default:
          return false
      }
    },
    getSelectOptions(fieldName, type) {
      switch (fieldName) {
        case 'state':
          return this.selectOptions
        case 'district':
          return this.getDistrictsSelectOptions(type)
        case 'city':
          return this.getCitiesSelectOptions(type)
        default:
          return []
      }
    },
    getShippingInputs(type) {
      if (this.countrySettings.length) {
        let inputs = {}
        const fieldSettings = []
        this.countrySettings.map((field, index) => {
          const { name } = field
          fieldSettings.push(field)
          if (
            index === 1 &&
            ['shipping', 'return'].includes(type) &&
            !this.isPOFlow
          ) {
            /**
             * inserting business name field after names (assuming the first 2 inputs are first and last, or last and first) for shipping address
             */
            fieldSettings.push({
              key: 'pp.summary.shipping.business',
              name: 'orgName',
              required: false,
              length: '30'
            })
          }
        })

        fieldSettings.map((field) => {
          const { key, name, length, required, ziplookup } = field
          inputs[name] = {
            inputProps: {
              name
            },
            eventHandlers: {}
          }
          const textInputProps = {
            component: AppInputNew,
            inputProps: {
              ...inputs[name].inputProps,
              autocomplete: AUTOCOMPLETE_ATTRIBUTES[name],
              maxLength: Number(length),
              readonly: this.isLoading,
              required,
              characterCountEnabled: true,
              lookup: ziplookup,
              error:
                this.shippingInfo[`${type}Address`].errorDetails.errorField ===
                name
                  ? true
                  : this.$v.shippingInfo[`${type}Address`].fields[name]
                  ? this.$v.shippingInfo[`${type}Address`].fields[name].$error
                  : false,
              validationMessage:
                this.shippingInfo[`${type}Address`].errorMessages[name],
              placeholder: this.locale[key],
              fieldValue: this.shippingInfo[`${type}Address`].fields[name],
              multiByteMessage: this.locale[MULTIBYTE_ERROR_MESSAGE[name]],
              multiByteCountries: this.multiByteCountries,
              displayPOBoxDisclaimer:
                name === 'street1' && !this.isPOFlow ? true : false,
              type: 'text'
            },
            eventHandlers: {
              onBlur: this.validateDetailsField(type),
              onChange: this.fieldChange(type),
              onInputApply: this.onKeyupfield(type)
            }
          }
          if (!this.usesSelectInput.includes(name)) {
            /**
             * text input
             */
            inputs[name] = { ...inputs[name], ...textInputProps }
          } else if (this.showSelectInput(name, type)) {
            /**
             * select input
             */
            inputs[name].component = AppSelect
            inputs[name].inputProps = {
              ...inputs[name].inputProps,
              autocomplete: AUTOCOMPLETE_ATTRIBUTES[name],
              options: this.getSelectOptions(name, type),
              readonly: this.isLoading,
              label: this.locale[key],
              selected: this.shippingInfo[`${type}Address`].fields[name],
              error: this.$v.shippingInfo[`${type}Address`].fields[name]
                ? this.$v.shippingInfo[`${type}Address`].fields[name].$error
                : false
            }
            inputs[name].eventHandlers = {
              onBlur: this.validateDetailsField(type),
              onChange: this.fieldChange(type),
              onKeyup: this.onKeyupfield(type)
            }
          } else if (this.showAlternativeTextInput(name, type)) {
            /**
             * show text input when options are not available for the select input
             */
            inputs[name].component = AppInputNew
            inputs[name].inputProps = {
              ...inputs[name].inputProps,
              autocomplete: AUTOCOMPLETE_ATTRIBUTES[name],
              maxLength: Number(length),
              readonly: this.isLoading,
              required,
              error:
                this.errorField === name
                  ? true
                  : this.$v.shippingInfo[`${type}Address`].fields[name]
                  ? this.$v.shippingInfo[`${type}Address`].fields[name].$error
                  : false,
              validationMessage:
                this.shippingInfo[`${type}Address`].errorMessages[name],
              placeholder: this.locale[key],
              fieldValue: this.shippingInfo[`${type}Address`].fields[name],
              multiByteMessage: this.locale[MULTIBYTE_ERROR_MESSAGE[name]],
              multiByteCountries: this.multiByteCountries,
              type: 'text'
            }
            inputs[name].eventHandlers = {
              onBlur: this.validateDetailsField(type),
              onChange: this.fieldChange(type),
              onInputApply: this.onKeyupfield(type)
            }
          }

          /**
           * making certain fields readonly/disabled
           */
          if (type === 'shipping' && ['state', 'postalcode'].includes(name)) {
            inputs[name].inputProps = {
              ...inputs[name].inputProps,
              readonly: true,
              disabled: inputs[name].component === AppSelect ? true : undefined // readonly will not work for select element as per HTML spec
            }
          }

          if (this.isPOFlow) {
            /**
             * For PO flow, only the names are editable
             */
            if (!['first_name', 'last_name'].includes(name)) {
              inputs[name].inputProps = {
                ...inputs[name].inputProps,
                readonly: true,
                disabled:
                  inputs[name].component === AppSelect ? true : undefined // readonly will not work for select element as per HTML spec
              }
            }
          }

          /**
           * remove postalcode field if no value is entered by the Advisor in Core when the field is optional
           */
          if (type === 'shipping' && name === 'postalcode') {
            if (
              !this.shippingInfo[`${type}Address`].fields[name] &&
              !required
            ) {
              delete inputs[name]
            }
          }
        })
        return inputs
      }
    },
    getTaxInputs(type) {
      const taxFieldsConfig = this.TAX_FIELDS[this.countrycode]

      if (taxFieldsConfig) {
        let inputs = {}
        const taxFieldsForType = taxFieldsConfig.find(
          ({ type: taxType }) => taxType === type
        )

        taxFieldsForType.fields.forEach((field) => {
          const { i18n, name, maxLength, mask, required, transformInput } =
            field

          inputs[name] = {
            component: AppInputNew,
            inputProps: {
              name
            },
            eventHandlers: {
              onBlur: this.taxFieldOnBlur,
              onChange: transformInput
                ? ({ name, value }) =>
                    this.taxFormFieldChange({
                      name,
                      value: transformInput(value)
                    })
                : this.taxFormFieldChange,
              onInputApply: this.onKeyupfield(type)
            }
          }
          inputs[name].inputProps = {
            ...inputs[name].inputProps,
            autocomplete: AUTOCOMPLETE_ATTRIBUTES[name],
            maxLength: maxLength || undefined,
            required,
            error:
              typeof this.$v.taxDetails.fields[name] !== 'undefined'
                ? this.$v.taxDetails.fields[name].$error
                : false,
            placeholder: this.locale[i18n],
            mask,
            fieldValue: this.taxDetails.fields[name],
            type: 'text'
          }
        })

        return inputs
      }
    },
    async nextStep(value) {
      this.isNextStepProcessing = true
      this.$store.commit('RESET_QUOTE_ERROR')

      try {
        /**
         * construct payload for New Quote API
         */
        let createQuotepayload = {
          // Commented out for now. Will be added back with Griffin integration
          //   smsOptIn: this.smsUpdatesOptIn,
          //   customerEmail: this.shippingInfo.email,
          //   customerPhoneNumber: this.shippingInfo.phoneNumber,
          shippingAddress: this.shippingInfo.shippingAddress.fields
        }

        createQuotepayload.shippingAddress = addressStateToPayloadMapping(
          createQuotepayload.shippingAddress,
          {
            country: this.selectedCountry,
            orgName: this.shippingInfo.shippingAddress.fields.orgName
          }
        )
        if (this.collectReturnAddress) {
          createQuotepayload.returnAddress = this.returnAddressSameAsShipping
            ? this.shippingInfo.shippingAddress.fields
            : this.shippingInfo.returnAddress.fields

          if (this.returnAddressSameAsShipping) {
            this.$store.dispatch('setReturnAddressFromShippingAddress')
          }

          createQuotepayload.returnAddress = addressStateToPayloadMapping(
            createQuotepayload.returnAddress,
            {
              country: this.selectedCountry,
              orgName: this.shippingInfo.returnAddress.fields.orgName
            }
          )
        }

        /**
         * Add tax details
         */
        if (this.collectTaxDetails) {
          const taxFieldsConfig = this.TAX_FIELDS[this.selectedCountry].find(
            ({ type: taxType }) => taxType === this.taxDetails.type
          )
          const normalizeValues = (taxFields, config) => {
            let newTaxFieldsWithDefaults = {}
            Object.keys(taxFields).forEach((fieldName) => {
              const fieldConfig =
                config.fields.find(({ name }) => name === fieldName) || {}
              const defaultValue = fieldConfig.default
              newTaxFieldsWithDefaults[fieldName] =
                taxFields[fieldName] || defaultValue

              if (fieldConfig.normalizeValue) {
                newTaxFieldsWithDefaults[fieldName] =
                  fieldConfig.normalizeValue(
                    newTaxFieldsWithDefaults[fieldName]
                  )
              }

              // remove the key if the value is null or undefined
              if (
                newTaxFieldsWithDefaults[fieldName] === undefined ||
                newTaxFieldsWithDefaults[fieldName] === null
              ) {
                delete newTaxFieldsWithDefaults[fieldName]
              }
            })

            return newTaxFieldsWithDefaults
          }

          const normalizedFormFields = normalizeValues(
            this.taxDetails.fields,
            taxFieldsConfig
          )

          /**
           * if fields are empty, API won't accept `taxDetails` key in the payload
           */
          if (Object.keys(normalizedFormFields).length > 0) {
            createQuotepayload.taxDetails = {
              ...normalizedFormFields,
              country: this.selectedCountry,
              type: this.taxDetails.type || undefined
            }
          }
          if (this.collectTaxAddress) {
            createQuotepayload.taxAddress = this.taxAddressSameAsShipping
              ? this.shippingInfo.shippingAddress.fields
              : this.shippingInfo.taxAddress.fields

            createQuotepayload.taxAddress = addressStateToPayloadMapping(
              createQuotepayload.taxAddress,
              {
                country: this.selectedCountry
              }
            )
          }
        }

        const isZipCodeRequiredOrHasValue =
          this.isZipCodeRequired ||
          createQuotepayload.shippingAddress.postalCode ||
          (createQuotepayload.returnAddress &&
            createQuotepayload.returnAddress.postalCode)
        const needsZipCodeValidation =
          isZipCodeRequiredOrHasValue && this.shouldZipCodeLookUp

        /**
         * perform zipcode validation
         */
        if (needsZipCodeValidation && !this.isPOFlow) {
          const zipCodeValidationPromises = []
          if (
            createQuotepayload.shippingAddress &&
            createQuotepayload.shippingAddress.postalCode
          ) {
            zipCodeValidationPromises.push(
              this.$store.dispatch('fetchStates', {
                zipCode: createQuotepayload.shippingAddress.postalCode,
                country: this.selectedCountry,
                field: 'postalcode',
                type: 'shipping'
              })
            )
          }
          if (
            createQuotepayload.returnAddress &&
            createQuotepayload.returnAddress.postalCode
          ) {
            zipCodeValidationPromises.push(
              await this.$store.dispatch('fetchStates', {
                zipCode: createQuotepayload.returnAddress.postalCode,
                country: this.selectedCountry,
                field: 'postalcode',
                type: 'return'
              })
            )
          }

          await Promise.all(zipCodeValidationPromises)

          if (
            this.shippingInfo.shippingAddress.status.error ||
            this.shippingInfo.returnAddress.status.error
          ) {
            return
          }
        }

        if (this.isPOFlow) {
          /**
           * For PO flow, no need to make quote call or do zip validations.
           * All the details are directly sent to the payment API towards the end.
           */
          const { token, locale } = this.$route.params
          const route = `/${token}/${locale}/purchase-order`
          this.$router.push(route)
        } else {
          /**
           * Call Quote API
           */
          this.$store
            .dispatch('createQuote', createQuotepayload)
            .then(async () => {
              const { token, locale } = this.$route.params
              const route = `/${token}/${locale}/billing`
              this.$router.push(route)
            })
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.isNextStepProcessing = false
      }
    },

    fieldChange(type) {
      return (change) => {
        if (this.isChile) {
          if (change.name === 'state') {
            this.$store.dispatch('fetchDistrictsForShippingInfo', {
              country: this.selectedCountry,
              stateCode: change.value,
              languageCode: this.languageCode,
              type
            })
          }

          if (change.name === 'district') {
            /**
             * city is populated based on district (in Chile), so resetting
             */
            this.$store.dispatch('resetField', { type, field: 'city' })
          }
        }

        this.setflag = false
        this.isDoublebyte = false
        if (this.selectedCountry === 'JPN' && change.name === 'postalcode') {
          change.value = change.value.replace('−', '-').replace('ー', '-')
        }
        const eventType = event.type
        this.$store
          .dispatch('shippingFormFieldChange', {
            type,
            ...change,
            eventType
          })
          .then(() => {
            /**
             * reset error so that validation error isn't shown to the user before they could type anything
             * does not reset the error when user types in 'Other', only resets when user uses dropdown 'Other'
             */
            if (change.value === 'Other' && eventType === 'change') {
              this.$v.shippingInfo[`${type}Address`].fields[
                change.name
              ].$reset()
            }
          })
      }
    },

    taxFormFieldChange(change) {
      this.$store.dispatch('taxFormFieldChange', change)
    },
    taxFieldOnBlur(change) {
      this.$v.taxDetails.fields[change.name].$touch()
    },

    validateField(data) {
      this.isDoublebyte = false
      this.setflag = false
      this.$v[data.name].$touch()
    },
    validateDetailsField(type) {
      return (data) => {
        this.isDoublebyte = false
        this.setflag = false
        const addressfields = `${type}Address`
        if (this.$v.shippingInfo[addressfields].fields[data.name]) {
          this.$v.shippingInfo[addressfields].fields[data.name].$touch()
          if (data.name === 'postalcode' && this.shouldZipCodeLookUp) {
            if (data.value === '') {
              return
            }
            if (this.selectedCountry === 'JPN') {
              let postCodeVal = ''

              if (this.shippingInfo[addressfields].fields.postalcode !== null) {
                postCodeVal = this.shippingInfo[addressfields].fields.postalcode
                  .replace('−', '-')
                  .replace('ー', '-')
              }
              if (
                postCodeVal.indexOf('-') === -1 &&
                this.shippingInfo[addressfields].fields.postalcode !== null
              ) {
                var postCodeVal1 = postCodeVal.slice(0, 3)
                var postCodeVal2 = postCodeVal.slice(3, 7)
                postCodeVal = `${postCodeVal1}-${postCodeVal2}`
              }

              if (
                postCodeVal.indexOf('-') !== -1 &&
                this.shippingInfo[addressfields].fields.postalcode !== null
              ) {
                if (
                  this.shippingInfo[addressfields].fields.postalcode !== null
                ) {
                  if (data.value === this.zipCodeLookup) {
                    return
                  }
                  this.$store
                    .dispatch('fetchStates', {
                      zipCode: postCodeVal,
                      country: this.selectedCountry,
                      field: data.name,
                      type
                    })
                    .then(() => {
                      if (this.errorField !== 'postalcode') {
                        this.$store.dispatch('setZipCodeLookup')
                      }
                    })
                }
              }
            } else {
              if (data.value === this.zipCodeLookup) {
                return
              }
              this.$store
                .dispatch('fetchStates', {
                  zipCode: data.value,
                  country: this.selectedCountry,
                  field: data.name,
                  type
                })
                .then(() => {
                  if (this.errorField !== 'postalcode') {
                    this.$store.dispatch('setZipCodeLookup')
                  }
                })
            }
          }
        }
      }
    },
    onKeyupfield(type) {
      return (data) => {
        if (isit.doubleByte(data) && this.setflag === false) {
          this.isDoublebyte = false
          this.setflag = true
        } else {
          this.isDoublebyte = true
          this.setflag = false
        }

        if (
          this.$v.$invalid === false &&
          this.error === false &&
          this.detailsInfo.country_3 === 'JPN'
        ) {
          this.isDoublebyte = true
        }
      }
    },
    selectTaxType(event) {
      if (event.target.value !== this.taxDetails.type) {
        this.$store.dispatch('changeTaxType', event.target.value)
        /**
         * reset Tax form error when Tax type changes
         * Some tax type share similar fields causing duplicate errors to show up for multiple tax types
         */
        this.$v.taxDetails.$reset()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'ac-sasskit/core';
@import 'ac-sasskit/base';
@import 'ac-forms/core';
@import 'ac-forms/base';
@import 'ac-forms/modules/form-icons';
@import 'ac-forms/modules/form-textbox';
@import '@/styles/variables.scss';
@import 'ac-forms/modules/form-tooltip';
@import 'ac-forms/modules/form-selector';
@import 'ac-forms/modules/form-alert';
@import 'ac-forms/modules/form-checkbox';

.tax__section-addressheading {
  margin: $app-margin-xxxx-sm 0;
  font-weight: 600;
  width: fit-content;
}

.form-tooltip-info-override {
  width: 15rem;
  margin-bottom: 10px;
  bottom: 18px;
  right: 0px;
}

.form-tooltip-after-override {
  margin-left: 0.5rem;
}

.form-tooltip-content-override {
  padding: 0.75rem 1.0625rem 0.8125rem;
}

.form-alert {
  padding: 0.75rem 1rem; // overriding because of corrupt padding values for this class
  p /deep/ a {
    text-decoration: underline;
  }
}

.checkbox__container {
  white-space: nowrap;
  display: flex;
  align-items: baseline;
  margin-bottom: $app-margin-xx-xs;
}

.checkbox__label {
  color: swatch(glyph-gray);
  @include typography(body);
  @include direction(
    padding-left,
    $app-margin-xxx-xs,
    padding-right,
    $app-margin-xxx-xs
  );
  white-space: normal;
}

.smsOptIn {
  display: flex;
  align-items: baseline;
}

.shipping_address__container {
  padding-bottom: 14px;
}

.return_address__container {
  border-top: 1px solid #d2d2d7;
}

.inline-field {
  display: inline-block;
}

.inline-field + .inline-field {
  padding-left: 1rem;
}

.checkout {
  color: swatch(glyph-gray);
  text-align: left;
}

.payment__selection {
  @include direction(margin-left, 0px, margin-right, 0px);
  @include viewport(large) {
    border-bottom: 1px solid swatch(fill-gray-tertiary);
  }
  @include viewport(small) {
    border-bottom: none;
  }
}
h1:focus {
  outline: none;
}
.checkout__header-label {
  h1 {
    margin-block-start: 0;
    margin-block-end: 0;
    @include typography(eyebrow);
  }
  @include typography(eyebrow);
  padding-bottom: $app-margin-xxx-sm;
  border-bottom: 1px solid swatch(fill-gray-tertiary);
}

.checkout__header-price {
  padding-top: $app-padding-x-sm;
  @include typography(body-reduced);
  color: black;
  float: right;
  text-align: right;
  border-bottom: 1px solid swatch(fill-gray-tertiary);
}
.checkout__header-price:lang(ar-AE) {
  padding-top: $app-padding-x-sm;
  @include typography(body-reduced);
  color: black;
  float: right;
  text-align: left;
}

.checkout__header-text {
  color: black;
}

.checkout__header,
h2 {
  margin-top: $app-margin-lg;
  @include typography(headline-reduced);
  @include viewport(medium) {
    @include typography(eyebrow-super);
  }
  color: swatch(glyph-gray);
}
h2:focus {
  outline: none;
}
.checkout_button {
  padding-top: $app-margin-xx-xs;
  padding-bottom: $app-margin-xx-xs;
}
.form-label-small {
  margin-top: 4px;
  letter-spacing: unset;
  @include typography(body);
  @include viewport(medium) {
    @include typography(caption);
  }
  @include viewport(small) {
    @include typography(caption);
  }
}
.checkout_button--creditline1 {
  @include typography(body);
  font-weight: 600;
  color: swatch(glyph-gray);
}
.checkout_button--creditline2 {
  @include typography(body-reduced);
  color: swatch(glyph-gray);
}

.card {
  border: none;
}
.card-body {
  margin-bottom: -50px;
  padding: 0;
  @include viewport(small) {
    border-bottom: none;
    margin-bottom: -50px !important;
  }
  @include viewport(large) {
    margin-bottom: -50px;
  }
  @include viewport(medium) {
    margin-bottom: -40px;
  }
}

.creditcard__section-details {
  margin-top: $app-margin-x-sm;
  margin-bottom: $app-margin-lg;
}

.creditcard__section-label {
  @include typography(body);
  color: swatch(glyph-gray);
  margin-bottom: $app-margin-xx-xs;
}

.body__text {
  @include typography(body);
  color: swatch(glyph-gray);
  margin-bottom: $app-margin-xx-xs;
}

.creditcard__section--field {
  padding-bottom: 0;
}

.creditcard__section-addressheading {
  display: block;
  margin: $app-margin-xxxx-sm 0;
  @include typography(body);
  font-weight: 600;
  color: swatch(glyph-gray);
}

.form-selector-group {
  margin-bottom: 36px;
  margin-top: 0px;
  @include viewport(small) {
    margin-bottom: $app-padding-xxxx-lg;
  }
}
.form-selector .form-selector-label {
  margin-bottom: $app-margin-xx-xs;
}

.form-selector-label .form-selector-left-col {
  @include direction(text-align, left, text-align, right);
  @include direction(padding-left, 0, padding-right, 0);
  position: relative;
  align-self: center;
}

.form-selector-label {
  min-height: 4.88235rem;
  @include viewport(medium) {
    min-height: 4.77rem;
  }
}
.form-selector {
  @include direction(padding-right, 0px, padding-left, 0px);
}
.form-selector-input {
  padding: $app-padding-xxx-sm;
}
.form-tooltip-content {
  width: auto;
  height: auto;
}
.continue__review {
  padding-top: $app-margin-lg;
  @include viewport(small) {
    padding-top: 0px;
  }

  @include direction(padding-right, 10px, padding-left, 0px);
  @include direction(text-align, right, text-align, left);
}
.row {
  padding-left: 0px;
  padding-right: 0px;
}

.continue__review .button {
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
}

.termsFailureModal__heading {
  text-align: center;
  @include typography(headline-reduced);
  padding-bottom: $app-padding-xxxx-sm;
  @include viewport(small) {
    padding-bottom: $app-margin-xxxx-sm;
  }
}
.termsFailureModal__content {
  @include direction(text-align, center, text-align, center);
  @include typography(body-tight);
  padding-top: $app-padding-zero;
  margin-top: 0 !important;
  p {
    margin: 0px;
  }
}

.termsFailureModal__action {
  margin-top: $app-margin-xxxx-sm;
  text-align: -webkit-center;
  .button {
    display: block;
    margin-top: $app-margin-xxxx-xs;
    @include viewport(small) {
      width: 70%;
    }
  }
}
.form-textbox-righticon {
  @include direction(text-align, right, text-align, left);
}
.displayNone,
.modal-close {
  display: none;
  @include direction(left, 24px, right, 24px);
}
.column {
  @include direction(padding-right, 0px, padding-left, 0px);
}

img {
  width: 70px;
  height: 28px;
  display: block;
}

.button-spinner {
  top: rem(10);
  position: relative;
  text-align: -webkit-center;
}

#loading-text {
  display: none;
}

.error-message {
  margin: $app-margin-xx-xds 0;
  border-color: rgba(227, 0, 0, 0.4);
  background: #fff2f4;
  border-radius: 5px;
  border: 1px solid swatch(glyph-red);
}
.error-message-form {
  padding-left: $app-padding-xx-sm;
  vertical-align: middle;
}
.error-message__text {
  display: flex;
  color: #e30000;
  font-weight: $app-font-weight-lg;
  font-size: 0.9375em;
  padding: $app-margin-xxx-sm $app-padding-x-sm $app-padding-xx-m
    $app-padding-xx-m;
  img {
    width: 14px;
    height: 20px;
  }
}
.confirmModal__action {
  margin-top: $app-margin-xxxx-sm;
  text-align: -webkit-center;
  .button {
    display: block;
    margin-top: $app-margin-x-xss;
    @include viewport(small) {
      width: 70%;
    }
  }
}
</style>