import { render, staticRenderFns } from "./app-input-new.vue?vue&type=template&id=5ef15a98&scoped=true"
import script from "./app-input-new.vue?vue&type=script&lang=js"
export * from "./app-input-new.vue?vue&type=script&lang=js"
import style0 from "./app-input-new.vue?vue&type=style&index=0&id=5ef15a98&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ef15a98",
  null
  
)

export default component.exports